.rich-text,
.mce-content-body {

  font-family: 'BlissPro', sans-serif;
  color: black;
  font-size: 14px;
  line-height: 18px;

  h1, h2, h3, h4, h5, h6 {
    color: #fca300;
    font-size: 18px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 18px;
  }

  & > * {
    margin-bottom: 18px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: #fca300;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: #fca300;
    }
    &:focus {
      text-decoration: none;
      color: #fca300;
    }
  }

  table {
    .table();
  }

  img {
    max-width: 100% !important;
    height: auto !important;
  }

}