
@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-LightItalic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-LightItalic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-LightItalic.ttf')  format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Light.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Light.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Light.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Light.ttf')  format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-HeavyItalic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-HeavyItalic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-HeavyItalic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-HeavyItalic.ttf')  format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Italic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Italic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Italic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Italic.ttf')  format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBoldItalic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBoldItalic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBoldItalic.ttf')  format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-MediumItalic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-MediumItalic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-MediumItalic.ttf')  format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro.ttf')  format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro-Heavy';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Heavy.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Heavy.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Heavy.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Heavy.ttf')  format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBold.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBold.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraBold.ttf')  format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-BoldItalic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-BoldItalic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-BoldItalic.ttf')  format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Medium.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Medium.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Medium.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Medium.ttf')  format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLight.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLight.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLight.ttf')  format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Bold.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-Bold.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Bold.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-Bold.ttf')  format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'BlissPro';
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLightItalic.eot');
	src: url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLightItalic.woff') format('woff'),
		 url('/wp-content/themes/girshon/dist/fonts/BlissPro-ExtraLightItalic.ttf')  format('truetype');
	font-weight: 200;
	font-style: italic;
}
