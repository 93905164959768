.colored {
	color: #fca300;	
}

a.colored {
	&:hover,
	&:focus {
		color: #fca300;
		text-decoration: none;
	}
	&:hover {
		text-decoration: underline;
	}
}

.button-yellow {

	.btn();

	background: none;
	color: #fca300;
	font-weight: 300;
	text-transform: uppercase;
	padding: 8px 12px;
	border: 1px solid #fca300;
	border-radius: 0;
	white-space: normal;
    text-align: left;
    margin-top: 15px;

	&:hover,
	&:focus {
		color: #fca300;
	}

	&--two {
		@media (min-width: @screen-sm-min) {
			max-width: 135px;
		}
		@media (max-width: @screen-xs-max){
			width: 100%;
		}
	}
}

.video-16-9 {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		border: none;
	}
}

.highlighted {
	display: inline-block;
	font-size: 14px;
	line-height: 18px;
	color: white;
	background-color: #fca300;
	margin-top: 4px;
	padding: 2px 4px;
}

@margin-x-big: 60px;
@margin-big: 44px;
@margin-mid: 31px;
@margin-min: 18px;
.mt-x-big {
	margin-top: @margin-x-big;
}
.mt-big {
	margin-top: @margin-big;
}
.mt-mid {
	margin-top: @margin-mid;
}
.mt-min {
	margin-top: @margin-min;
}
.mt-none {
	margin-top: 0;
}

.mb-x-big {
	margin-bottom: @margin-x-big;
}
.mb-big {
	margin-bottom: @margin-big;
}
.mb-mid {
	margin-bottom: @margin-mid;
}
.mb-min {
	margin-bottom: @margin-min;
}
.mb-none {
	margin-bottom: 0;
}

.text-small {
	color: #000000;
	font-size: 12px;
}

.text-mid {
	color: #000000;
	font-size: 16px;
}

.text-big {
	font-size: 26px;
	color: #000000;
}

.heading {
	&-page {
		color: #868686;
		font-weight: 300;
		font-style: italic;
		font-size: 35px;
		border-bottom: 1px solid #d8dde2;
		padding-bottom: 1px;
	}
	&-big {
		font-size: 28px;
		line-height: 28px;
		font-weight: 300;
		color: #fca300;
		text-transform: uppercase;
	}
	&-small {
	    font-size: 18px;
	    font-weight: 500;
	    color: #5e5e5e;
	    text-transform: uppercase;
	}
}

.box-gray {
	padding: 20px;
    border: 6px solid #e0dddd;
}